import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import { cssTierIcon } from "@/game-tft/OverviewChampions.style.jsx";
import {
  SelectGameMode,
  SelectRank,
  SelectRegion,
} from "@/game-tft/Selects.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { getAugment, useLocalizedAugments } from "@/game-tft/use-augments.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import DataTable from "@/shared/DataTable.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import { FlexContainer, WinRate } from "@/shared/InfiniteTable.style.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const cssGap = () => css`
  display: flex;
  gap: var(--sp-2);
`;

const columns = [
  {
    label: ["tft:augments", "Augments"],
    dataKey: "augments",
    align: "left",
    primary: true,
  },
  {
    label: ["tft:pickRate", "Pick Rate"],
    dataKey: "augment_unit_pick_rate",
    align: "right",
    isStat: true,
  },
  {
    label: ["tft:PRChange", "PR Change"],
    dataKey: "pick_rate_improvement",
    align: "right",
    isStat: true,
  },
  {
    label: ["common:avgPlace", "Avg. Place"],
    dataKey: "augment_unit_avg_placement",
    align: "right",
    isStat: true,
  },
];

function ChampionAugmentsTable({ indivUnitStats, set }) {
  const state = useSnapshot(readState);
  const setFromParam = useSetByParam(true);
  set = set || setFromParam;
  const augments = state.tft[set]?.augments;
  const { t } = useTranslation();
  const locale = getLocale();
  const isLoaded = useIsLoaded();
  const localizedAugments = useLocalizedAugments(set);

  const augmentStats = indivUnitStats?.top_augments;

  // Renderers
  const colRenderer = useCallback(
    ({ dataKey, rowData, rowIndex }) => {
      if (dataKey === "rank") {
        return <div className="type-body2">{rowIndex + 1}</div>;
      }
      if (dataKey === "augments") {
        const augmentInfo = getAugment(augments, rowData.augment_api_name);
        if (!augmentInfo) return "";
        return (
          <AugmentName>
            <AugmentTooltip augment={augmentInfo.key} className="augmentName">
              <img
                src={StaticTFT.getAugmentImage(augmentInfo.key, set)}
                alt={rowData.augment_api_name}
                width={32}
                height={32}
              />
              <p>
                {localizedAugments[augmentInfo.key]?.name ?? augmentInfo?.name}
              </p>
            </AugmentTooltip>
          </AugmentName>
        );
      }
      if (dataKey === "tier") {
        const TierIcon = getTierIcon(rowData.tier);
        return TierIcon ? <TierIcon className={cssTierIcon()} /> : null;
      }
      if (
        dataKey === "augment_unit_pick_rate" &&
        typeof rowData.augment_unit_pick_rate === "number"
      ) {
        return (
          <WinRate
            color={getWinRateColor(rowData.augment_unit_pick_rate, 0.05)}
          >
            {t("lol:percent", "{{number}}%", {
              number: (rowData.augment_unit_pick_rate * 100).toLocaleString(
                locale,
                {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                },
              ),
            })}
          </WinRate>
        );
      }
      if (
        dataKey === "pick_rate_improvement" &&
        typeof rowData.pick_rate_improvement === "number"
      ) {
        return (
          <WinRate color={getWinRateColor(rowData.pick_rate_improvement, 0.01)}>
            {t("lol:percent", "{{number}}%", {
              number: (rowData.pick_rate_improvement * 100).toLocaleString(
                locale,
                {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                },
              ),
            })}
          </WinRate>
        );
      }
      if (
        dataKey === "augment_unit_avg_placement" &&
        typeof rowData.augment_unit_avg_placement === "number"
      ) {
        return (
          <WinRate
            color={getWinRateColor(rowData.augment_unit_avg_placement, 4, true)}
            className="type-body2"
          >
            {rowData.augment_api_name?.includes("_Legend_")
              ? "-"
              : rowData.augment_unit_avg_placement.toLocaleString(locale, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })}
          </WinRate>
        );
      }
    },
    [augments, locale, localizedAugments, set, t],
  );

  if (!Array.isArray(augmentStats) || !augmentStats?.length) return "";

  const cols = columns.map((col) => {
    return {
      display: col.labelSub ? (
        <div className="flex column align-center gap-1">
          <div>{t(col.label[0], col.label[1])}</div>
          <div className="shade3">
            {t(col.labelSub[0], col.labelSub[1], col.labelSub[2])}
          </div>
        </div>
      ) : (
        t(col.label[0], col.label[1])
      ),
      align: col.align,
      primary: col.primary,
      isStat: col.isStat,
    };
  });
  const rows = augmentStats.map((item, i) => {
    return columns.map((col) => {
      return {
        display: colRenderer({
          dataKey: col.dataKey,
          rowData: item,
          rowIndex: i,
        }),
        value: item[col.dataKey],
        className: col.className,
      };
    });
  });

  return (
    <div className="flex column gap-4">
      <div className={cssGap()}>
        <SelectRegion />
        <SelectGameMode />
        <SelectRank />
      </div>
      <FlexContainer $opacity={isLoaded ? 1 : 0.5}>
        <DataTable
          cols={cols}
          rows={rows}
          sortDir="DESC"
          sortCol={2}
          indexCol
        />
      </FlexContainer>
    </div>
  );
}

export default ChampionAugmentsTable;

const AugmentName = styled("div")`
  > div {
    display: flex;
    gap: var(--sp-2);
    align-items: center;
  }
`;
