import { css, styled } from "goober";

export const ItemRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: var(--sp-2);
  color: var(--shade1);

  &:nth-child(even) {
    background: var(--shade8);
  }

  .col {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      padding-inline-start: var(--sp-6);
    }

    &:last-of-type {
      padding-inline-end: var(--sp-6);
    }
  }

  .build {
    flex: 1.8;
    justify-content: flex-start;
    gap: var(--sp-1);
    img {
      border-radius: 100%;
    }
  }

  .pickRate,
  .avgPlace {
    justify-content: center;
    flex: 1;
  }

  .tier {
    svg {
      width: var(--sp-6);
      height: var(--sp-6);
    }
  }
  .recipe {
    svg {
      width: var(--sp-4);
      height: var(--sp-4);
      fill: var(--shade3);
    }
  }
`;

export const cssBasicItem = () => css`
  width: var(--sp-6) !important;
  height: var(--sp-6) !important;
`;

export const cssPadding = () => css`
  padding: var(--sp-2);
`;
