import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { appURLs } from "@/app/constants.mjs";
import LolColors from "@/game-lol/constants/colors.mjs";
import {
  AbilityDescription,
  AbilityImage,
  AbilityInfo,
  AbilityName,
  AbilityStat,
  AbilityStatName,
  AbilityStatValue,
  AbilityTitle,
  AbilityType,
  ChampionAbility,
  ChampionAbilityBG,
  ChampionCutout,
  ChampionCutoutFrame,
  ChampionInfo,
  ChampionInfoLeft,
  ChampionInfoRight,
  ChampionStats,
  CutoutBase,
  CutoutChampion,
  ManaStart,
  StarContainer,
  Stat,
  StatRow,
  StatTitle,
  StatValue,
} from "@/game-tft/ChampionInfo.style.jsx";
import { STAT_MULTIPLIERS } from "@/game-tft/constants.mjs";
import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import { SelectStar } from "@/game-tft/Selects.jsx";
import HextechStatArmorIcon from "@/inline-assets/hextech-stat-armor.svg";
import HextechStatAttackDamageIcon from "@/inline-assets/hextech-stat-attack-damage.svg";
import HextechStatAttackRangeIcon from "@/inline-assets/hextech-stat-attack-range.svg";
import HextechStatAttackSpeedIcon from "@/inline-assets/hextech-stat-attack-speed.svg";
import HextechStatHealthIcon from "@/inline-assets/hextech-stat-health.svg";
import HextechStatMagicResistIcon from "@/inline-assets/hextech-stat-magic-resist.svg";
import { useRoute } from "@/util/router-hooks.mjs";

const isNotAvailable = (stat) => stat === "N/A";

const Info = ({ champion, selectedSet }) => {
  const { searchParams } = useRoute();
  const { t } = useTranslation();
  const { ability, stats, key } = champion;

  const selectedStatMultiplier = useMemo(
    function getStatMultiplier() {
      const star = searchParams.get("star");
      if (/[1-3]/.test(star)) return STAT_MULTIPLIERS[Number(star) - 1];
      return STAT_MULTIPLIERS[0];
    },
    [searchParams],
  );

  if (!ability) return null;

  return (
    <ChampionInfo>
      <ChampionInfoLeft>
        <ChampionCutoutFrame>
          <ChampionCutout>
            <CutoutBase />
            <CutoutChampion
              style={{
                backgroundImage: `url(${appURLs.CDN}/blitz/tft/champion_cutouts/${selectedSet}/${key}.webp)`,
              }}
            />
          </ChampionCutout>
        </ChampionCutoutFrame>
        <StarContainer>
          <SelectStar />
        </StarContainer>
      </ChampionInfoLeft>
      <ChampionInfoRight>
        <ChampionAbility>
          <AbilityInfo>
            <AbilityImage
              src={`${appURLs.CDN}/blitz/tft/champion_abilities/${selectedSet}/${key}.webp`}
            />
            <AbilityTitle>
              <AbilityType className="type-overline">
                <span>{ability.type}</span>
                {!!ability.manaCost && (
                  <span>{t("tft:stats.cost", "Cost")}</span>
                )}
              </AbilityType>
              <AbilityName className="type-subtitle2">
                <span>{ability.name}</span>
                {!!ability.manaCost && (
                  <div>
                    <ManaStart>{ability.manaStart}&nbsp;/&nbsp;</ManaStart>
                    <span>{ability.manaCost}</span>
                  </div>
                )}
              </AbilityName>
            </AbilityTitle>
            <AbilityDescription className="type-caption">
              <ParsedDescription
                text={ability.description}
                iconSize={"var(--sp-4)"}
              />
            </AbilityDescription>
            {ability.stats.map((abilityStat, i) => (
              <AbilityStat key={i} className="type-caption">
                <AbilityStatName>{abilityStat.type}</AbilityStatName>
                <AbilityStatValue>{abilityStat.value}</AbilityStatValue>
              </AbilityStat>
            ))}
          </AbilityInfo>
          <ChampionAbilityBG $keyChamp={key} />
        </ChampionAbility>
        <ChampionStats>
          <StatRow>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.health", "Health")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatHealthIcon
                  className={css`
                    fill: ${LolColors.stats.health} !important;
                  `}
                />
                {!isNotAvailable(stats.defense.health)
                  ? stats.defense.health * selectedStatMultiplier
                  : t("common:notAvailable", "N/A")}
              </StatValue>
            </Stat>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.armor", "Armor")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatArmorIcon
                  className={css`
                    fill: ${LolColors.stats.armor} !important;
                  `}
                />
                {stats.defense.armor}
              </StatValue>
            </Stat>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.magicResist", "Magic Resist")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatMagicResistIcon
                  className={css`
                    fill: ${LolColors.stats.magicResist} !important;
                  `}
                />
                {stats.defense.magicResist}
              </StatValue>
            </Stat>
          </StatRow>
          <StatRow>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.attackDamage", "Atk Damage")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatAttackDamageIcon
                  className={css`
                    fill: ${LolColors.stats.attackDamage} !important;
                  `}
                />
                {!isNotAvailable(stats.offense.damage)
                  ? stats.offense.damage * selectedStatMultiplier
                  : t("common:notAvailable", "N/A")}
              </StatValue>
            </Stat>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.attackSpeed", "Atk Speed")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatAttackSpeedIcon
                  className={css`
                    fill: ${LolColors.stats.attackSpeed} !important;
                  `}
                />
                {stats.offense.attackSpeed}
              </StatValue>
            </Stat>
            <Stat>
              <StatTitle className="type-overline">
                {t("tft:stats.range", "Range")}
              </StatTitle>
              <StatValue className="type-body1">
                <HextechStatAttackRangeIcon
                  className={css`
                    fill: var(--shade2) !important;
                  `}
                />
                {stats.offense.range}
              </StatValue>
            </Stat>
          </StatRow>
        </ChampionStats>
      </ChampionInfoRight>
    </ChampionInfo>
  );
};

export default Info;
