import { keyframes, styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import { appURLs } from "@/app/constants.mjs";

export const ChampionInfo = styled("div")`
  display: flex;
  margin-bottom: var(--sp-12);

  ${mobile} {
    flex-wrap: wrap;
    margin-bottom: var(--sp-6);
  }
`;

export const ChampionInfoLeft = styled("div")`
  width: 208px;
  position: relative;

  ${tablet} {
    flex: 1;
  }

  ${mobile} {
    margin-bottom: var(--sp-6);
  }
`;

// Champ cutout
export const ChampionCutout = styled("div")`
  position: absolute;
  width: 320px;
  height: 320px;
  top: calc(-1 * var(--sp-14));
  left: calc(-1 * var(--sp-18));
  background-repeat: no-repeat;
  pointer-events: none;
`;

const animBaseEntrance = () => keyframes`
	0% {
    opacity: 0;
    transform: translateY(5px);
  }
  60% {
    opacity: 0;
    transform: translateY(5px);
  }
`;

export const CutoutBase = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(${appURLs.CDN}/blitz/tft/champion_cutouts/base.png) no-repeat
    center;
  background-size: 320px;
  pointer-events: none;
  animation: ${animBaseEntrance} 1.2s ease-out;
  transition: var(--transition);
`;

const animChampionEntrance = () => keyframes`
	0% {
    opacity: 0;
    transform: translateY(calc(-1 * var(--sp-2_5)));
  }
  60% {
    opacity: 0;
    transform: translateY(calc(-1 * var(--sp-2_5)));
  }
`;

export const CutoutChampion = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 320px;
  animation: ${animChampionEntrance} 1.2s ease-out;
  transition: var(--transition);

  ${mobile} {
    background-size: 250px;
    background-repeat: no-repeat;
    top: var(--sp-12_5);
    left: var(--sp-12_5);
  }
`;

export const ChampionCutoutFrame = styled("div")`
  position: relative;
  width: 208px;
  height: 255px;

  &:hover {
    ${CutoutChampion} {
      transform: translateY(-5px) scale(1.05);
    }
    ${CutoutBase} {
      transform: translateY(5px);
    }
  }

  ${tablet} {
    margin: 0 auto;
  }
`;

// Right side ////////////////////////////////
export const ChampionInfoRight = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${tablet} {
    flex: 1.5;
  }
`;

// Ability block
export const ChampionAbility = styled("div")`
  position: relative;
  background: var(--shade9);
  border-radius: var(--br);
`;

export const AbilityInfo = styled("div")`
  position: relative;
  padding: var(--sp-4) var(--sp-4) var(--sp-4) var(--sp-9);
  z-index: 1;

  ${mobile} {
    padding-left: var(--sp-16);
  }
`;

export const AbilityImage = styled("img")`
  position: absolute;
  height: var(--sp-10);
  width: var(--sp-10);
  top: var(--sp-3);
  left: calc(var(--sp-4) * -1);
  border-radius: var(--br);

  ${mobile} {
    width: var(--sp-8);
    height: var(--sp-8);
    left: var(--sp-4);
  }
`;

export const AbilityTitle = styled("div")`
  margin-bottom: var(--sp-2);
`;

export const AbilityName = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const ManaStart = styled("span")`
  color: var(--shade5);
`;

export const AbilityType = styled("div")`
  display: flex;
  margin-bottom: var(--sp-1);
  justify-content: space-between;
  color: var(--shade3);
`;

export const AbilityDescription = styled("div")`
  color: var(--shade1);
  margin-bottom: var(--sp-3);
  padding-bottom: var(--sp-3);
  border-bottom: var(--sp-px) solid rgba(255, 255, 255, 0.075);
`;

export const AbilityStat = styled("div")`
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    margin: 0;
  }
`;

export const AbilityStatName = styled("span")``;

export const AbilityStatValue = styled("span")`
  color: var(--shade0);
  text-align: right;
`;

export const ChampionAbilityBG = styled("div")<{ $keyChamp?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: var(--br);
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--sp-12_5));
    bottom: calc(-1 * var(--sp-12_5));
    left: calc(-1 * var(--sp-12_5));
    right: calc(-1 * var(--sp-12_5));
    background-position: -322px -82px;
    filter: blur(var(--sp-2_5));
    z-index: 0;
    opacity: 0.075;
    ${({ $keyChamp }) =>
      `background-image: url(${appURLs.CDN}/blitz/centered/${$keyChamp}_Splash_Centered_0.webp)`};
  }
`;

// Stats block
export const ChampionStats = styled("div")`
  margin: var(--sp-6) 0 0;
`;

export const StatRow = styled("div")`
  display: flex;
  padding: 0 var(--sp-3);

  &:nth-child(1) {
    margin-bottom: var(--sp-6);
  }

  ${mobile} {
    justify-content: space-between;
    padding: 0 var(--sp-4);
  }
`;

export const Stat = styled("div")`
  width: 33.33%;

  ${mobile} {
    width: auto;
  }
`;

export const StatTitle = styled("div")`
  color: var(--shade3);
`;

export const StatValue = styled("div")`
  display: flex;
  align-items: center;

  svg {
    margin-top: calc(-1 * var(--sp-1));
    margin-right: var(--sp-1_5);
    height: var(--sp-3);
    width: var(--sp-3);
  }
`;

export const StarContainer = styled("div")`
  display: flex;
  justify-content: center;
  & svg {
    width: var(--sp-7) !important;
    height: auto !important;
  }
`;
