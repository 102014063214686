import { css, keyframes, styled } from "goober";
import { mobile, mobileSmall, tablet } from "clutch/src/Style/style.mjs";

import TftColors from "@/game-tft/colors.mjs";
import getChampionCenteredImage from "@/game-tft/get-champion-centered-image.mjs";

const animRotate = () => keyframes`
  to { transform: rotate(360deg); }
`;

export const ChampionHeader = styled("div")`
  margin-bottom: var(--sp-8);

  ${tablet} {
    margin: 0 0 1.875rem;
  }

  .flex {
    display: flex;
    &.mt-10 {
      margin-top: calc(var(--sp-px) * 10);
    }
  }
`;

export const ChampionImageFrame = styled("div")<{
  $cost: keyof typeof TftColors.rarityGradient;
}>`
  box-sizing: border-box;
  position: relative;
  width: var(--sp-30);
  margin-right: var(--sp-4);
  padding: var(--sp-0_5);
  background: ${({ $cost }) => TftColors.rarityGradient[$cost]};
  border-radius: var(--br);
  height: var(--sp-20);

  ${({ $cost }) =>
    $cost === 7 &&
    css`
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        top: -40%;
        bottom: -40%;
        left: -20%;
        right: -20%;
        background: ${TftColors.rarityGradient[$cost]};
        animation: ${animRotate} 2s linear infinite;
      }
    `}

  ${tablet} {
    height: var(--sp-20);
  }

  ${mobileSmall} {
    height: 65px;
  }
`;
export const ChampCost = styled("div")`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: ${({ color }) => `${color}`};
  display: flex;
  height: var(--sp-5);
  width: 1.875rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-top-left-radius: var(--br);
  border-bottom-right-radius: var(--br);
  text-shadow: 0 var(--sp-px) 0 rgba(0, 0, 0, 0.6);

  svg {
    margin: calc(-1 * var(--sp-0_5)) var(--sp-0_5) 0 0;
    height: var(--sp-2_5);
    width: var(--sp-2_5);
    filter: drop-shadow(0 var(--sp-px) rgba(0, 0, 0, 0.3));
  }
`;
export const ChampionImageImg = styled("div")<{
  $championKey?: string;
  $set?: string;
}>`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  background: ${({ $championKey, $set }) =>
      `url(${getChampionCenteredImage($championKey, $set)})`}
    no-repeat var(--shade9);
  background-position: 15% 20%;
  background-size: cover;
  border-radius: var(--br-sm);
  border: var(--sp-0_5) solid var(--shade9);

  ${mobileSmall} {
    height: var(--sp-15);
  }
`;
export const ChampionHeaderRight = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
export const ChampionHeaderName = styled("div")`
  display: flex;
  align-items: center;
  gap: var(--sp-2);

  span {
    font-size: var(--sp-3);
    color: var(--shade3);
    margin-top: var(--sp-2);

    ${mobile} {
      display: none;
    }
  }

  ${mobile} {
    & + span {
      color: var(--shade3);
    }
  }
`;
export const Types = styled("div")`
  display: flex;
  margin-top: var(--sp-1);
  ${mobile} {
    gap: var(--sp-2);
    flex-wrap: wrap;
  }
  margin-bottom: var(--sp-1);
`;
export const Type = styled("div")`
  display: flex;
  align-items: center;
  color: var(--shade0);
  margin-right: var(--sp-6);

  ${mobile} {
    margin-right: 0;
    align-items: center;
    > div {
      margin-left: 0;
      width: fit-content;
    }
    > p {
      font-size: var(--sp-3);
      white-space: nowrap;
    }
  }
`;
export const TypeName = styled("div")`
  margin: 0;
  margin-left: var(--sp-2);
  margin-top: var(--sp-1);

  ${mobile} {
    margin-top: 0;
  }
`;
export const SubHeader = styled("div")`
  display: flex;
  justify-content: space-between;
`;
