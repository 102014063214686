import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";

import { cssPadding, ItemRow } from "@/game-tft/ChampionBestItems.style.jsx";
import { CombinedItemImage } from "@/game-tft/CommonComponents.jsx";
import ItemTooltip from "@/game-tft/ItemToolTip.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import { WinRate } from "@/shared/InfiniteTable.style.jsx";

const ChampionBestItems = ({ champion, indivUnitStats, selectedSet }) => {
  const { t } = useTranslation();
  const set = useSetByParam(true);
  const bestItems = indivUnitStats.item_trios?.slice(0, 5) || [];
  if (!champion || !indivUnitStats || !bestItems?.length) return "";

  return (
    <Card padding={0} title={t("tft:bestItems", `Best Items`)}>
      <ItemRow>
        <div className="col build">
          <div className="type-caption">{t("common:build", "Build")}</div>
        </div>
        <div className="col pickRate">
          <div className="type-caption">{t("tft:pickRate", "Pick Rate")}</div>
        </div>
        <div className="col avgPlace">
          <div className="type-caption">
            {t("common:avgPlace", "Avg. Place")}
          </div>
        </div>
      </ItemRow>

      {bestItems.map((item, i) => {
        const pickRate = (item.pick_rate * 100).toFixed(2) + "%";
        const avgPlace = item.avg_placement.toFixed(1);

        return (
          <ItemRow key={i}>
            <div className="col build">
              {item.item_api_names?.map((apiKey) => {
                return (
                  <ItemTooltip item={apiKey} key={apiKey}>
                    <CombinedItemImage
                      src={StaticTFT.getItemImage(apiKey, selectedSet)}
                      alt={apiKey}
                    />
                  </ItemTooltip>
                );
              })}
            </div>
            <div className="type-caption col pickRate">
              <WinRate color={getWinRateColor(pickRate, 0.001)}>
                {pickRate}
              </WinRate>
            </div>
            <div className="type-caption col avgPlace">
              <WinRate color={getWinRateColor(avgPlace, 4, true)}>
                {avgPlace}
              </WinRate>
            </div>
            {/* <div className="col tier">
                <TierIconTooltip>
                  <TierIcon />
                </TierIconTooltip>
              </div> */}
            {/* <div className="col recipe">
                {buildsFrom.map((basicItem, i) => {
                  const notLast = i < buildsFrom.length - 1;
                  return (
                    <React.Fragment key={`${basicItem}_${i}`}>
                      <CombinedItemImage
                        src={StaticTFT.getItemImage(basicItem, selectedSet)}
                        alt={basicItem}
                        width="64px"
                        height="64px"
                        className={cssBasicItem()}
                      />
                      {notLast && <AddIcon />}
                    </React.Fragment>
                  );
                })}
              </div> */}
            {/* <div className="type-caption matches">
                {imatchesPlayedtem..toLocaleString(getLocale)}
              </div> */}
          </ItemRow>
        );
      })}

      <ButtonGroup block className={cssPadding()}>
        <Button
          href={`/tft/champion/${champion.key}/items?set=${set.replace(
            "set",
            "",
          )}`}
        >
          {t("common:viewAll", "View All")}
        </Button>
      </ButtonGroup>
    </Card>
  );
};

export default ChampionBestItems;
